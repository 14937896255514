document.addEventListener('DOMContentLoaded', () => {
    filiModal();

    function filiModal() {
        /**
         * Наименования классов
         */
        const className: IClassName = {
            msg: 'fili-modal-message--is-visible',
            overlay: 'fili-overlay--is-hidden',
            modal: {
                isVisible: 'fili-modal--is-visible',
                isHidden: 'fili-modal--is-hidden',
            },
        };

        const buttons = document.querySelectorAll(`[data-modal-button]`);
        const overlay = createOverlay(); // Создаем overlay
        const dataInnerButtons: NodeList = document.querySelectorAll('[data-button-modal]');
        const dataInnerModals: NodeList = document.querySelectorAll('[data-inner-modal]');
        const inputTels = document.querySelectorAll('[type="tel"]');
        const policyCheckbox = document.getElementById('fili-policy') as HTMLInputElement;
        const select = document.getElementById('prep-select') as HTMLSelectElement;
        // const advTitle: HTMLElement | null = document.getElementById('adverse-title');
        const filiForm = document.getElementById('fili-form') as HTMLFormElement;
        const successMsg = document.querySelector("[data-modal-message='message']");
        const hashName = window.location.hash;

        let modal = document.querySelector(`[data-modal-show="true"]`); // Если модалка должна открываться при загрузке
        // страницы
        let closeButton: NodeList;

        hashOnload();

        if (filiForm) {
            filiForm.addEventListener('submit', submitForm);
        }

        if (modal) {
            init();
        }

        /**
         * Функция самбита формы. Тут хранится вся логика при сабмите
         * @param {Event} e
         */
        function submitForm(e: Event) {
            e.preventDefault();

            if (successMsg) {
                const closeBtn = successMsg.querySelector("[data-modal-close='close']");
                successMsg.classList.add(className.msg);

                closeModal();

                /**
                 * Так как в функции closeModal Я скрываю оверлей, то для следующего окна об успешном принятии сообщения
                 * Я его вручную добавляю
                 */
                overlay.classList.remove(className.overlay);
                overlay.addEventListener('click', closeMsg);

                if (closeBtn) {
                    closeBtn.addEventListener('click', closeMsg);
                }
            }

            /**
             * Функция, отвечающая за скрытие сообщения
             */
            function closeMsg() {
                successMsg?.classList.remove(className.msg);
                overlay.classList.add(className.overlay);
                filiForm.reset();
            }
        }

        /**
         * Функция срабатывает при выборе внутренней вкладки. При клике, она сначала скрывает все,
         * а потом открывает выбранный. Находит по селектору data-
         */
        function changeInnerModal(this: HTMLElement): void {
            const name = this.dataset.buttonModal;
            const id = this.id;
            const targetModal = document.querySelector(`[data-inner-modal='${name}']`);

            if (!targetModal) {
                return;
            }

            dataInnerModals.forEach((modal: Node) => {
                if (modal instanceof HTMLElement) {
                    modal.classList.add('is--hidden');
                }
            });
            targetModal.classList.remove('is--hidden');

            /**
             * Тут Я прохожусь по всем кнопкам, чтобы присвоить активный класс правильному табу
             */
            dataInnerButtons.forEach((btn: Node) => {
                if (name) {
                    changeClassForButtons(btn as HTMLElement, name);
                }
            });

            /**
             * Проверяю ID и если это кнопка "Согласен" с обработкой перс. данных, то
             * тогда Я включаю это
             */
            if (id === 'policy-agree') {
                policyCheckbox.checked = true;
            }
        }

        function openModal(button: Node): void {
            button.addEventListener('click', function (this: HTMLElement) {
                const id = this.dataset.modalButton;
                let prepName = this.dataset.prepName;

                if (id) {
                    modal = document.querySelector(`[data-modal-id="${id}"]`); // Переопределяем модалку

                    if (modal) {
                        init();
                    }
                }

                /**
                 * Если имя препарата есть, то занесу его в селект
                 */
                if (select) {
                    const selectedOption: HTMLSelectElement | null = select.querySelector(`[data-value="${prepName}"]`)

                    if (selectedOption) {
                        changeSelectTitle(selectedOption.value);
                    }
                }
            });
        }

        /**
         * Создание overlay
         */
        function createOverlay(): HTMLElement {
            const overlay = document.createElement('div');
            overlay.classList.add('fili-overlay');
            overlay.classList.add(className.overlay);
            document.body.appendChild(overlay);
            return overlay;
        }

        function closeModal(): void {
            modal?.classList.add(className.modal.isHidden);
            modal?.classList.remove(className.modal.isVisible);

            closeButton.forEach((btn: Node) => {
                if (btn instanceof HTMLElement) {
                    btn.removeEventListener('click', closeModal);
                }
            });

            overlay.removeEventListener('click', closeModal); // Удаляем обработчик
            overlay.classList.add(className.overlay); // Скрываем оверлей

            const timerID = setTimeout(function () {
                modal?.classList.remove(className.modal.isHidden);
                clearTimeout(timerID);
            }, 500);
        }

        function init(): void {
            modal?.classList.remove(className.modal.isHidden);
            modal?.classList.add(className.modal.isVisible);
            overlay.classList.remove(className.overlay);

            if (modal) {
                closeButton = modal.querySelectorAll('[data-modal-close="close"]');
            }

            closeButton.forEach((btn: Node) => btn.addEventListener('click', closeModal));

            /**
             * Тут мы при открытии модального окна всегда показываем экран с формой для вопроса
             * Даже если закрыли его на другой вкладке
             */
            dataInnerModals.forEach((modal: Node) => {
                if (modal instanceof HTMLElement) {
                    const name = modal.dataset.innerModal;

                    if (!name) {
                        return;
                    }

                    modal.classList.add('is--hidden');

                    if (name === 'question') {
                        modal.classList.remove('is--hidden');
                    }
                }
            });

            /**
             * Тут Я вешаю обработчик на кнопки при открытии модального окна, а так же всегда устанавливаю активной кнопку на
             * форме "задать вопрос"
             */
            dataInnerButtons.forEach((btn: Node) => {
                changeClassForButtons(btn as HTMLElement, 'question');
                btn.addEventListener('click', changeInnerModal);
            });

            overlay.addEventListener('click', closeModal);

            if (select) {
                select.addEventListener('change', function () {
                    const prepName = this.value;
                    changeSelectTitle(prepName);
                });
            }

            if (hashName) {
                checkHash(hashName);
            }

            /**
             * Маска для номера телефона
             */
            inputTels.forEach(tel => new IMask(tel, {mask: '+{7}(000)000-00-00'}));
        }

        /** ----- Функции хелперы ----- **/

        /**
         * Функция меняет активный класс у кнопок. Выбирает по селектору активную кнопку и задает ей класс,
         * у остальных она убирает активный класс
         * @param {HTMLElement} btn  - кнопка
         * @param {string} selector - по которому ищем кнопку
         */
        function changeClassForButtons(btn: HTMLElement, selector: string): void {
            const activeClassName = 'fili-modal__content-link--active';

            btn.classList.remove(activeClassName);
            const activeBtn: HTMLElement | null = document.querySelector(
                `.fili-modal__content-link[data-button-modal='${selector}']`
            );

            activeBtn?.classList.add(activeClassName);
        }

        /**
         * Функция работы с select. Срабатывает как при открытии модалки, так и при change select
         */
        function changeSelectTitle(prepName: string): void {
            select.value = prepName;
        }

        /**
         * Функция очистки хеша
         */
        function checkHash(hash: string): void {
            const selectedOption: HTMLSelectElement | null = select.querySelector(`[data-value="${hashName.slice(1)}"]`)

            if (selectedOption) {
                changeSelectTitle(selectedOption.value);
            }
        }

        /**
         * Функция работает так: при загрузке Я проверяю хеш. Потом проверяю, совпадает ли хеш с value
         * в селекте с препаратами. Если да, то беру модальное окно, которое есть в select и присваиваю переменной modal
         * А дальше срабатывает тот же самый алгоритм
         */
        function hashOnload() {
            const selectedOption: HTMLSelectElement | null = select.querySelector(`[data-value="${hashName.slice(1)}"]`)

            if (selectedOption) {
                select.value = selectedOption.value;
            } else {
                select.value = '';
            }
            // Если селект не пустой
            if (select.value !== '') {
                modal = select.closest('[data-modal-id]');
            }
        }

        /**
         * Запуск события клика на модальное окно
         */
        buttons.forEach(openModal);
    }
});
